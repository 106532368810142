import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CustomMultiSelectWidget = (props) => {
    const {
        schema,
        options,
        value,
        onChange,
        onBlur,
        onFocus,
        disabled,
        readonly,
        id,
        label,
        required
    } = props;

    // Determine enum options from schema or options
    const enumOptions = schema.enum
        ? (schema.enum || []).map((value, index) => ({
            value,
            label: schema.enumNames?.[index] || value
        }))
        : (options.enumOptions || options.enum || []).map(option =>
            typeof option === 'object'
                ? option
                : { value: option, label: option }
        );

    const handleChange = (selectedValues) => {
        onChange(selectedValues);
    };

    const handleBlur = () => {
        onBlur(id, value);
    };

    const handleFocus = () => {
        onFocus(id, value);
    };

    return (
        <Select
            id={id}
            mode="multiple"
            style={{ width: '100%' }}
            size='small'
            placeholder={`Select ${label}`}
            value={value || []}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled || readonly}
            required={required}
        >
            {enumOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default CustomMultiSelectWidget;