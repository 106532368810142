import React, { useState } from "react";
import { Button, Popconfirm } from "antd";

const ButtonField = (props) => {
  console.log(props?.options?.submission, "jeeee");
  const { title } = props?.schema;
  const { onClick } = props?.uiSchema[`ui:options`];
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(true); // Show the Popconfirm when the button is clicked
    // If onClick is passed from uiSchema, call it as well
    if (onClick) {
      onClick();
    }
  };

  const handleConfirm = () => {
    // Navigate to the specified URL
    window.location.href = "https://manjushreefinance.com.np/";
  };

  const handleCancel = () => {
    console.log("User cancelled the action.");
  };

  return (
    <div>
      <Button
        htmlType={props?.options?.submission ? "submit" : "button"}
        type="primary"
        onClick={props?.options?.callHandleclick ? handleClick : undefined}
      >
        {props.label}
      </Button>

      {/* Popconfirm for confirmation */}
      <Popconfirm
        title="Do you want to open an account before proceeding with the Fixed Deposit account opening?"
        visible={visible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        onVisibleChange={(visible) => setVisible(visible)}
      />
    </div>
  );
};

export default ButtonField;
