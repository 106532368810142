"use client";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";

const NavigationButton = () => {
  return (
    <Button
      type="primary"
      icon={<ArrowRightOutlined />}
      iconPosition="end"
      onClick={() =>
        (window.location.href = "https://manjushreeapplication.server247.info")
      }
    >
      Proceed to  Homepage
    </Button>
  );
};

export default NavigationButton;
