import ButtonField from "../fields/ButtonField";
import {
  CaptchaWidget,
  CardWidget,
  CustomDatePicker,
  CustomFileUpload,
  CustomMultiSelectWidget,
  CustomTableWidget,
  MapWidget,
  NepaliDatePicker,
  PopupCheckboxWidget,
  TableWidgets,
 

} from "../widgets";
import CustomSelectWidget from "../widgets/CustomSelectWidget";
import CustomTextWidget from "../widgets/CustomTextWidget";

export const Widgets = {
  TextWidget: CustomTextWidget,
  SelectWidget: CustomSelectWidget,
  FileWidget: CustomFileUpload,
  MapWidget: MapWidget,
  TableWidget: TableWidgets,
  EnglishDatePicker: CustomDatePicker,
  NepaliDatePicker,
  CaptchaWidget,
  CardWidget,
  CustomTableWidget,
  PopupCheckboxWidget,
  CustomMultiSelectWidget,
  ButtonField


};
