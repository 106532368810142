import React from "react";
import { FileUploadProvider } from "./FileUploadContext"; // Assuming the file upload context is in FileUploadContext.js
import { AnonymousProvider } from "./anonymousContext"; // Assuming you saved the Anonymous context in AnonymousContext.js
import { DraftProvider } from "./DraftContext"; // Import the DraftProvider from DraftContext.js

export const CombinedProvider = ({ children }) => {
  return (
    // <TokenProvider>
    <AnonymousProvider>
      <FileUploadProvider>
        <DraftProvider>{children}</DraftProvider>
      </FileUploadProvider>
    </AnonymousProvider>
    // </TokenProvider>
  );
};

export default CombinedProvider;
