import axios from 'axios'
import { API_URL, BASE_URL } from './authConst'

const apiClient = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token')
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error.response) {
      // Handle specific error codes
      if (error.response.status === 401) {
        console.error('Unauthorized! Redirecting to login...')
      } else if (error.response.status === 403) {
        console.error('Forbidden access!')
      }
    }
    return Promise.reject(error)
  }
)

export default apiClient