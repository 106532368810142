// import { adToBs } from "@sbmdkl/nepali-date-converter";
// import { Form } from "antd";
// import moment from "moment";
// import NepaliDate from "nepali-date-converter";
// import { useEffect, useState } from "react";

// const NepaliDatePicker = (props) => {
//   const { value, onChange, options, label, placeholder, formContext } = props;
//   const { enforceAgeRestriction } = options || {};
//   const [date, setDate] = useState(value ? new NepaliDate(value) : null);
//   const [error, setError] = useState("");

//   useEffect(() => {
//     if (value) {
//       setDate(new NepaliDate(value));
//       setError(""); // Clear errors if value is updated externally
//     }
//   }, [value]);

//   // Get today's date in BS
//   const getTodayBS = () => {
//     try {
//       return new NepaliDate(adToBs(moment().format("YYYY-MM-DD")));
//     } catch (error) {
//       console.error("Error converting today's date:", error);
//       return null;
//     }
//   };

//   // Get 18 years ago in BS
//   const getEighteenYearsAgoBS = () => {
//     try {
//       const eighteenYearsAgo = moment()
//         .subtract(18, "years")
//         .format("YYYY-MM-DD");
//       return new NepaliDate(adToBs(eighteenYearsAgo));
//     } catch (error) {
//       console.error("Error converting 18 years ago date:", error);
//       return null;
//     }
//   };

//   // Handle date selection
//   const handleDateChange = (selectedDate) => {
//     setDate(selectedDate);

//     // if (
//     //   enforceAgeRestriction &&
//     //   formContext?.formData?.account_type_id !== "Minor Account"
//     // ) {
//     //   const adDate = selectedDate.format("YYYY-MM-DD", "en");
//     //   const age = moment().diff(moment(adDate, "YYYY-MM-DD"), "years");
//     //   if (age < 18) {
//     //     setError("You must be at least 18 years old.");
//     //     return;
//     //   }
//     // }

//     setError("");
//     onChange(selectedDate.format("YYYY-MM-DD", "en")); // Pass formatted date to parent
//     if (options?.onDateChange)
//       options.onDateChange(selectedDate.format("YYYY-MM-DD", "en"));
//   };

//   // Clear the date
//   const handleClearDate = () => {
//     setDate(null);
//     setError("");
//     onChange("");
//   };

//   // Get maximum selectable date
//   const getEighteenYearsAgoInBS = () => {
//     return new NepaliDate(
//       adToBs(moment().subtract(18, "years").format("YYYY-MM-DD"))
//     );
//   };

//   const handleMinorDate = () => {
//     if (formContext?.formData?.account_type_id === "Minor Account") {
//       return new NepaliDate(
//         adToBs(moment().subtract(16, "years").format("YYYY-MM-DD"))
//       );
//     }
//   };
//   return (
//     <Form.Item validateStatus={error ? "error" : ""} help={error}>
//       <div style={{ position: "relative", width: "100%" }}>
//         <NepaliDatePicker
//           value={date}
//           placeholder={placeholder || `Select ${label}`}
//           onChange={(e) => handleDateChange(e)}
//           lang="en"
//           showclear={false}
//           max={getEighteenYearsAgoInBS()}
//           // min={getEighteenYearsAgoInBS()}
//           className={
//             error
//               ? "error-input custom-date-picker-nepali"
//               : "custom-date-picker-nepali"
//           }
//         />

//         {date && (
//           <span
//             style={{
//               position: "absolute",
//               right: 10,
//               top: "50%",
//               transform: "translateY(-50%)",
//               fontSize: "12px",
//               cursor: "pointer",
//               color: "rgba(14, 11, 11, 0.45)",
//             }}
//             onClick={handleClearDate}
//           >
//             ✖
//           </span>
//         )}
//       </div>
//     </Form.Item>
//   );
// };

// export default NepaliDatePicker;

import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { adToBs, bsToAd } from "@sbmdkl/nepali-date-converter";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAnonymous } from "../context/anonymousContext";
import dayjs from "dayjs";

const NepaliDatePicker = (props) => {
  const { value, onChange, options, placeholder, label, formContext } = props;
  const { enforceAgeRestriction, name, futureDateShow } = options;
  const [date, setDate] = useState(value || "");
  const [error, setError] = useState("");
  const { status } = useAnonymous();

  useEffect(() => {
    if (value) {
      setDate(value);
      setError(""); // Clear any existing error when value changes
    }
  }, [value]);

  // Get today's date in BS
  const getTodayBS = () => {
    try {
      return adToBs(dayjs().format("YYYY-MM-DD"));
    } catch (error) {
      console.error("Error converting today's date:", error);
      return "";
    }
  };

  // Get 18 years ago in BS
  const getEighteenYearsAgoBS = () => {
    try {
      const eighteenYearsAgo = dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
      return adToBs(eighteenYearsAgo);
    } catch (error) {
      console.error("Error converting 18 years ago date:", error);
      return "";
    }
  };

  const handleDateChange = ({ bsDate, adDate }) => {
    setDate(bsDate);

    if (
      enforceAgeRestriction &&
      formContext?.formData?.salutation !== "MINOR"
    ) {
      const age = dayjs().diff(dayjs(adDate), "years");
      if (age < 18) {
        // setError("You must be at least 18 years old.");
        // message.error("You must be at least 18 years old.");
        return;
      }
    }

    setError("");
    onChange(bsDate);
    options.onDateChange && options.onDateChange(bsDate);
  };

  const handleClearDate = () => {
    setDate("");
    setError("");
    onChange("");
  };

  const getMaxDate = () => {
    if (futureDateShow) {
      return ""; // No restriction on future dates
    }
    return enforceAgeRestriction ? getEighteenYearsAgoBS() : getTodayBS();
  };
  return (
    <Form.Item validateStatus={error ? "error" : ""} help={error}>
      <div style={{ position: "relative", width: "100%" }}>
        <Calendar
          onChange={handleDateChange}
          defaultDate={date}
          hideDefaultValue={!value}
          value={date}
          theme="light"
          placeholder={`Select ${label}`}
          style={{
            width: "100%",
            borderRadius: "3px",
            border: "1px solid #d9d9d9",
            padding: "4px 11px",
            height: "44px",
          }}
          language="en"
          maxDate={getMaxDate()} // Restrict future dates
          disabled={status === "submitted"}
          className={error ? "error-input" : ""}
        />
        {date && (
          <CloseCircleOutlined
            style={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: "16px",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.45)",
            }}
            onClick={handleClearDate}
          />
        )}
      </div>
    </Form.Item>
  );
};

export default NepaliDatePicker;
