import React from "react";

const HomePage = () => {
  return (
    <div>
      <header className="">
        <div className="headerfull">
          <div className="container">
            <div className="nav-bar">
              <div className="align-items-center d-flex justify-content-between">
                <div className="home-img">
                  <a href="https://manjushreefinance.com.np">
                    <img
                      src="https://www.manjushreefinance.com.np/themes/manjushreefinance/images/logo.jpg"
                      alt="Machhapuchchhre Bank Limited"
                      title="Manjushree Finance Limited"
                      height="60px"
                      style={{ background: "white" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container py-5">
        <h1 className="text-white h3 mb-3 fw-700">Products</h1>
        <div className="row">
          <div className="col-sm-4">
            <a className="services services--secondary" href="">
              <div className="aspect-ratio-box">
                <img
                  className="aspect-ratio-box__media services__media"
                  src="https://bankxp.manjushreefinance.com.np/smartstatic/banners/products/manjushree_banner2.jpg_20240613142729718_9751.jpg"
                />
              </div>
              <div className="services__content services__content--spacing">
                <div className="services__top">
                  <div className="services__title">Web Login</div>
                  <div className="services__desc">
                    Manjushree Finance Limited offers a web-based login service
                    called Bankxp.
                  </div>
                </div>
                <div className="services__buttons mt-3 d-flex justify-content-end gap-2">
                  <button className="btn btn-warning">Learn more</button>
                  <button className="btn btn-warning-outline">
                    Learn more
                  </button>
                </div>
              </div>
            </a>
          </div><div className="col-sm-4">
            <a className="services services--secondary" href="">
              <div className="aspect-ratio-box">
                <img
                  className="aspect-ratio-box__media services__media"
                  src="https://bankxp.manjushreefinance.com.np/smartstatic/banners/products/manjushree_banner2.jpg_20240613142729718_9751.jpg"
                />
              </div>
              <div className="services__content services__content--spacing">
                <div className="services__top border-bottom">
                  <div className="services__title">Web Login</div>
                  <div className="services__desc">
                    Manjushree Finance Limited offers a web-based login service
                    called Bankxp.   Manjushree Finance Limited offers a web-based login service
                    called Bankxp. 
                  </div>
                      <h6 className="mt-3 fw-light">
                        Minmum Balance: <div className="fw-bold d-inline">Rs.555555</div>
                      </h6>
                </div>
                <div className="services__buttons mt-3 d-flex justify-content-end gap-2">
                  <button className="btn btn-warning">Learn more</button>
                  <button className="btn btn-warning-outline">
                    Learn more
                  </button>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

//
