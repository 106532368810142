import { useState, useEffect } from "react";
import { useAnonymous } from "../context/anonymousContext"; // Assuming anonymous context is already defined
import { useParams } from "react-router-dom";
import apiClient from "../constants/apiHelper";
import { BASE_URL, PROJECT_ID } from "../constants/authConst";

const useFetchFormDetails = () => {
  const token = sessionStorage.getItem("token");
  const { slug } = useParams();
  //console.log(slug, "df");
  const {
    setAnonymous,
    setSlug,
    setSteps,
    setIsAccountValidation,
    setBrandingScript,
  } = useAnonymous();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFormDetails = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(
          `${BASE_URL}/forms/${
            slug && slug !== ":slug" ? slug : PROJECT_ID
          }`
        );
        if (response.data) {
          const form = response?.data;

          // const validation = response.data.find(
          //   (item) => item.include_otp_validation === "Yes"
          // );
          if (form) {
            setSlug(form.slug);
            setAnonymous(form.anonymous === 1);
            setIsAccountValidation(form.include_account_validation === "Yes");
            setBrandingScript(form.branding_script);
            sessionStorage.setItem("branding_script", form.branding_script);
            const stepSlugs = form.steps.map((step) => step.step_slug);
            setSteps(stepSlugs);
          } else {
            setError("Form not found.");
          }
        } else {
          setError("Failed to retrieve form data. Please try again.");
        }
      } catch (error) {
        setError(
          "An error occurred while retrieving the form data. Please try again."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFormDetails();
  }, [token, setAnonymous, setSlug, setSteps]);

  return { loading, error };
};

export default useFetchFormDetails;
