import React from "react";
import { Card, Button, Typography, message } from "antd";
import {
  CheckCircleFilled,
  CopyOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavigationButton from "../component/NavigationButton";

const { Title, Paragraph } = Typography;

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { slug } = useParams();
  // Getting data from the location state
  const draftUrl = state?.draftUrl;
  const submissionId = state?.id;
  const submissionType = state?.submissionType;

  // Handler for copying text (either draft URL or submission ID)
  const handleCopy = () => {
    const textToCopy = submissionType === "draft" ? draftUrl : submissionId;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() =>
        message.success(
          `${
            submissionType === "draft" ? "Draft URL" : "Submission ID"
          } copied to clipboard!`
        )
      )
      .catch(() =>
        message.error(
          `Failed to copy ${
            submissionType === "draft" ? "Draft URL" : "Submission ID"
          }.`
        )
      );
  };

  // Navigate to home
  const handleGoHome = () => {
    navigate(`/services/${slug}`);
  };

  return (
    <div
      className={`container mx-auto my-20 px-4 border-t-2 border-red-900`}
      style={{
        padding: "10px",
        margin: "45px 0px",
        borderRadius: "10px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "600px",width:"100%",
        
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "30px 0px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleFilled
          style={{
            color: "#4C8844",
            fontSize: "10rem",
            marginBottom: "10px",
          }}
        />
        <h2 className="text-2xl fw-800 text-center mb-2">Thank You !</h2>

        <p className="mb-5">Your request has been submitted successfully.</p>

        <br />

        <NavigationButton />
      </div>
    </div>
  );
};

// Styling
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f2f5",
  },
  card: {
    width: 600,
    padding: "20px",
    textAlign: "center",
  },
  copyButton: {
    marginTop: "20px",
    marginRight: "10px",
  },
  homeButton: {
    marginTop: "20px",
  },
};

export default SuccessPage;
