import React, { useEffect } from "react";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAnonymous } from "../context/anonymousContext";
import useFetchToken from "../hooks/useFetchToken";
import useFetchFormDetails from "../hooks/useFetchSlugAndSubmissionId";

function Landingpage() {
  const navigate = useNavigate();
  const { token } = useFetchToken();
  const { loading: formLoading } = useFetchFormDetails();

  const { anonymous, slug, steps } = useAnonymous();

  if (formLoading) {
    <Spin size="large" spinning={true} fullscreen />;
  }

  useEffect(() => {

    if (!formLoading && slug && steps) {
      // if (!anonymous) {
      //   navigate(`/services/${slug}/${steps[0]}`);
      // } else {
      //   navigate(`/services/${slug}/${steps[0]}`);
      // }
      navigate(`/services/${slug}/${steps[0]}`);
    }
  }, [token, slug, steps, anonymous, navigate]);

  return null;
}

export default Landingpage;
