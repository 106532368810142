import { Button, Modal } from "antd";
import React from "react";
import TermsAndCondition from "../constants/termsAndCondition";

const ModalWidget = ({
  modalTitle,
  isOpen,
  closeTermsModal,
  setFormData,
  formData,
  setIsAccepted,
}) => {
  return (
    <Modal
      title={modalTitle}
      centered
      open={isOpen}
      onCancel={() => {
        closeTermsModal();
        setIsAccepted(false);
        setFormData({ ...formData, accept: false });
      }}
      width={800}
      footer={
        <>
          <Button
            type="primary"
            onClick={() => {
              closeTermsModal();
              setIsAccepted(true);
              setFormData({ ...formData, accept: true });
            }}
          >
            Accept
          </Button>
        </>
      }
    >
      <div
        style={{
          padding: "20px",
          fontSize: "14px",
          lineHeight: "1.6",
          textAlign: "justify",
          height: "70vh",
          overflow: "auto",
        }}
      >
        <TermsAndCondition />
      </div>
    </Modal>
  );
};

export default ModalWidget;
