import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoutes from "./protectedRoutes";
import AccountValidationForm from "../pages/AccountValidationForm";
import OtpForm from "../pages/OtpForm";
import DynamicFormGenerator from "../pages/DynamicFormGenerator";
import SuccessPage from "../pages/SuccessPage";
import Landingpage from "../pages/Landingpage";
import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage ";
import BrandingWrapper from "../shared/BrandingWrapper";
import NabilLayout from "../layout/NabilLayout";

const MainRoutes = () => {
  return (
    <Routes>
      {/* Redirect root to the desired path */}
      <Route path="/" element={<Navigate to="services/:slug" replace />} />
      <Route path="/services/:slug" element={<Landingpage />} />
      <Route path="/homepage" element={<HomePage />} />
      <Route
        element={
          <BrandingWrapper>
            <ProtectedRoutes />
          </BrandingWrapper>
        }
      >
        <Route
          path="/services/:slug/:stepslug/account-validation"
          element={<AccountValidationForm />}
        />
        <Route
          path="/services/:slug/:stepslug/otp-form"
          element={<OtpForm />}
        />
        <Route
          path="/services/:slug/:stepslug/otp-form/:id"
          element={<OtpForm />}
        />
        {/* <Route 
          path="/:slug/application-form" 
          element={<DynamicFormGenerator />} 
        /> */}
        <Route
          path="/services/:slug/:stepslug"
          element={<DynamicFormGenerator />}
        />
        <Route
          path="/services/:slug/:stepslug/:id"
          element={<DynamicFormGenerator />}
        />
        <Route
          path="/services/:slug/:stepslug/success-page"
          element={<SuccessPage />}
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default MainRoutes;
