import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CascadeWidget = (props) => {
  const { options, value, onChange: onFormChanged, formContext } = props;
  const { enumOptions, fetchOptionsFrom, dependsOn, onChange } = options;
  const [localOptions, setLocalOptions] = useState(enumOptions || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      if (fetchOptionsFrom) {
        setLoading(true);
        try {
          const parentValue = dependsOn ? formContext[dependsOn] : null;
          if (dependsOn && !parentValue) {
            setLoading(false);
            return;
          }

          const url = fetchOptionsFrom.replace(':value', parentValue || '');
          const response = await fetch(url);

          if (!response.headers.get('content-type')?.includes('application/json')) {
            const text = await response.text();
            throw new Error(`Expected JSON but received: ${text}`);
          }

          const data = await response.json();
          const filteredData = Array.isArray(data) ? data : Object.values(data).flat();
          const relevantOptions = dependsOn ? filteredData.filter(option => option[dependsOn] === parentValue) : filteredData;

          setLocalOptions(relevantOptions.map(option => ({
            value: option.value,
            label: option.label
          })));
        } catch (error) {
          console.error('Error fetching options:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOptions();
  }, [fetchOptionsFrom, dependsOn, formContext]);

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    onFormChanged(value);
  };

  return (
    <Select value={value} onChange={handleChange} placeholder="Select an option" loading={loading}>
      {localOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CascadeWidget;
