import React from "react";

const TermsAndCondition = () => {
  return (
    <>
      <span>
        Any Nabil Visa Electron Card issued is subject to these terms and
        conditions of use. Any use of the card at Merchant Establishment /
        Automated Teller Machine (ATM) will automatically constitute the
        acceptance to these terms and conditions.
      </span>
      <ol>
        <li>
          In these terms and conditions:
          <ol type="i">
            <li>
              &apos;The Bank&apos; means &apos;Nabil Bank Limited&apos; (NABIL)
              who is duly licensed to issue Visa Electron Cards and its
              successor.
            </li>
            <li>&apos;The card&apos; means valid Nabil Visa Electron Card.</li>
            <li>
              &apos;The Cardholder&apos; means an individual, mentioned in the
              card application form, to whom and for whose exclusive use the
              card has been issued.
            </li>
            <li>
              &apos;Account&apos; means savings account/Current/Corporate
              account maintained by the Bank in the name of the cardholder.
            </li>
            <li>
              &apos;Merchant Establishment&apos; means the establishment with
              which the authorized member of &apos;Visa International&apos; has
              entered into an agreement for honoring &apos;The Card&apos; on
              proper presentation.
            </li>
            <li>
              &apos;Transaction&apos; means the use of the card to avail
              services and/or to purchase goods and/or to draw cash at any
              Automated Teller Machine (ATM) or merchant with Electronic Data
              Capture Device (POS) who displays the Visa acceptance logo.
            </li>
            <li>
              &apos;Settlement of Transaction&apos; means real-time debit of the
              nominated account for the transaction amount originated at the
              merchant establishment or ATM and debit of the nominated account
              for the service charges/fees.
            </li>
            <li>
              &apos;PIN&apos; means the personal identification number generated
              with the card, for use in ATM and/or PIN-enabled POS terminals to
              identify the genuine cardholder. The cardholder is fully
              responsible for keeping the PIN unknown to others, as the same can
              allow transactions at ATM and/or PIN-enabled POS terminals.
            </li>
          </ol>
        </li>
        <li>
          <ol type="i">
            <li>The card is the property of the Bank at all times.</li>
            <li>The card is non-transferable.</li>
            <li>
              On receipt of the card, the cardholder shall sign with a ball pen
              on the reverse of the Card in the space provided, as per his/her
              signature appearing in the card application. In case the card is
              lost/stolen/misplaced, the cardholder shall be liable for all
              charges incurred due to use of such lost/stolen/misplaced card.
            </li>
            <li>
              The Bank reserves the right to seize/cancel the card so issued to
              any cardholder, if found at a later date, the information
              submitted by the cardholder is false and/or he/she is misusing the
              card.
            </li>
            <li>
              The Bank reserves the right to withdraw any or all of the
              privileges attached to &apos;The Card&apos; at any time and to
              call upon the cardholder to surrender the card without assigning
              any reason whatsoever.
            </li>
            <li>
              Upon termination of membership of the Card for any reason
              whatsoever, it shall be returned to the Card Division or nearest
              branch of the Bank within 2 days from the date of receipt of
              notice thereto.
            </li>
            <li>
              Use of the Card after notice of withdrawal of the privileges is
              fraudulent and the cardholder may be subjected to legal action by
              the Bank in accordance with the Commercial Bank Act and Debt
              Recovery Act.
            </li>
          </ol>
        </li>
        <li>
          <ul>
            <li>
              If the Card is lost/stolen, the Card Division of the Bank shall be
              informed immediately through phone/telex/fax/telegram followed by
              a written application duly signed by the Cardholder.
            </li>
            <li>
              For issuance of a replacement Card, the fee as per the Annexure or
              revised and fixed by the Bank from time to time shall be charged
              upon receiving lost/stolen card report in the prescribed format
              from the Cardholder.
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              Subject to proper presentation of the Card at the Merchant
              Establishment, it will be honored. The Card can be used in an
              electronic environment only, such as an ATM and POS terminal.
            </li>
            <li>
              The Bank shall not be liable if the Merchant Establishment is
              unable to honor the Card.
            </li>
            <li>
              The Bank shall not be responsible for any defective merchandise
              purchased or services availed by the Cardholder.
            </li>
            <li>
              The Cardholder is not authorized to claim a refund in cash
              directly from the Merchant Establishment.
            </li>
            <li>
              The transaction log of ATM transactions shall be the conclusive
              proof of the charges recorded therein.
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              The Bank shall levy fees/service charges to the Cardholders as per
              the Annexure or as revised by the Bank from time to time.
            </li>
            <li>
              Special services (e.g., airlines refund, communication service)
              may attract service charges.
            </li>
            <li>
              It is the sole responsibility of the Cardholder to reconcile
              transactions with the account statement every month.
            </li>
          </ul>
        </li>
        <li>
          The Bank reserves the right to add and/or amend these terms and
          conditions without notice.
        </li>
        <li>
          All disputes are subject to the jurisdiction of courts in Nepal.
        </li>
        <li>
          The Cardholder must follow the prevailing Foreign Currency rules and
          regulations.
        </li>
      </ol>
    </>
  );
};

export default TermsAndCondition;
