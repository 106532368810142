/* eslint-disable react/prop-types */
import { Button, Col, Flex, Popconfirm, Row, Typography } from "antd";
import { PlusIcon, Trash2Icon, TrashIcon } from "lucide-react";
import { normalizeTitle } from "../utils";
const { Text } = Typography;
const ArrayFieldTemplate = (props) => {
  const { items, canAdd, onAddClick, title } = props;

  return (
    <>
      <Row gutter={[16, 16]}>
        {items.map((item, index) => (
          <Col key={item.key} span={24}>
            <Flex
              justify="space-between"
              align="top"
              style={{ marginBottom: "-15px", marginTop: "20px" }}
            >
              <Text strong underline code>{`${normalizeTitle(title)} - ${
                index + 1
              }`}</Text>
              <>
                {item.hasRemove && (
                  <Popconfirm
                    title="Delete"
                    description="Are you sure to delete?"
                    onConfirm={item.onDropIndexClick(item.index)}
                  >
                    <Button type="primary" danger className="m-0 p-2">
                      <Trash2Icon />
                    </Button>
                  </Popconfirm>
                )}
              </>
            </Flex>
            {item?.children}
          </Col>
        ))}
        {canAdd && (
          <Button
            type="button"
            onClick={onAddClick}
            style={{
              width: "100%",
              backgroundColor: "green",
              color: "white",
              marginTop: "1rem",
            }}
          >
            <PlusIcon /> Add Item
          </Button>
        )}
      </Row>
    </>
  );
};

export default ArrayFieldTemplate;
