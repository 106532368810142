export const slugToTitle = (title) => {
  const normalized = title
    .replace(/[-_]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2");

  return normalized
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const transformErrorsMessage = (errors) => {
  return errors.map((error) => {
    if (error.name === "pattern") {
      return {
        ...error,
        message: "Invalid Input",
      };
    }
    return error;
  });
};

export const normalizeTitle = (title) => {
  if (!title) return "";

  const spacedTitle = title
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  return spacedTitle
    .trim()
    .replace(/\s+/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
