import { message } from "antd";
import { useMemo } from "react";

export function transformErrors(errors) {
  //console.log(errors, "err");
  const requiredFieldErrors = errors.filter((error) => error.name === 'required');
  const missingFields = [];

  // Filter out errors with specific messages to skip
  const filteredErrors = errors.filter(
    (error) =>
      error.message !== 'must be equal to one of the allowed values' &&
      error.message !== 'must be object' &&
      error.message !== 'schema is invalid: data/properties/tenure/enum must NOT have duplicate items (items ## 3 and 5 are identical)'

  );

  if (requiredFieldErrors.length > 0) {
    filteredErrors.forEach((error) => {
      if (error.name === 'required') {
        // Extract the last part of the property path (the field name)
        const fieldName = error.property.split('.').pop();
        missingFields.push(fieldName);
        error.message = `Required`;
      }
    });

    // Get the total number of missing fields
    const totalMissingFields = missingFields.length;

    // Get the first field that has an error
    const firstMissingField = missingFields[0];

    // Show a toast message with the total number and the first missing field
    // message.error(`Total fields missing: ${totalMissingFields}. First missing field: ${firstMissingField}.`);

    // Try to find the first field and focus on it
    let fieldElement = document.querySelector(`[name$="${firstMissingField}"]`); // Match the field name
    if (!fieldElement) {
      // Try the root prefix, as @rjsf sometimes adds a prefix like root_
      fieldElement = document.querySelector(
        `[id$="_root_${firstMissingField}"]`
      );
    }
    if (!fieldElement) {
      // Try a direct ID match
      fieldElement = document.querySelector(`#root_${firstMissingField}`);
    }

    // Focus on the field if found
    if (fieldElement && typeof fieldElement.focus === "function") {
      fieldElement.focus(); // Focus on the field
    } else {
      console.error("Unable to focus on the field:", firstMissingField);
    }
  }

  return filteredErrors; // Return the modified errors
}
