import React, { useState, useEffect } from "react";
import { Steps } from "antd";
// import "antd/dist/reset.css";
import { useAnonymous } from "../context/anonymousContext";
import { useParams, useNavigate } from "react-router-dom";
import useFetchSlugAndSubmissionId from "../hooks/useFetchSlugAndSubmissionId";
import { IdCard, BriefcaseBusiness, Eye } from "lucide-react";

const { Step } = Steps;

const capitalizeFirstLetter = (string) => {
  return string
    .replace(/-/g, " ")
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ProgressBar = ({steps}) => {
  const {  setCurrentStep } = useAnonymous();
  const { id: paramsId, stepslug, slug } = useParams();
  const navigate = useNavigate();
  const { loading: formLoading, error: formError } = useFetchSlugAndSubmissionId();

  const validSteps = Array.isArray(steps) ? steps : [];
  const [currentStepIndex, setCurrentStepIndex] = useState();

  const iconComponents = [
    <IdCard style={{ fontSize: 24 }} />, 
    <BriefcaseBusiness style={{ fontSize: 24 }} />,
    <Eye style={{ fontSize: 24 }} />
  ];

  useEffect(() => {
    if (stepslug && validSteps.length > 0) {
      const stepIndex = validSteps.findIndex((step) => step === stepslug);
      if (stepIndex !== -1) {
        setCurrentStep(stepIndex);
        setCurrentStepIndex(stepIndex);
      }
    }
  }, [stepslug, validSteps, setCurrentStep]);

  useEffect(() => {
    if (currentStepIndex !== -1) {
      sessionStorage.setItem("currentStep", currentStepIndex);
    }
  }, [currentStepIndex]);

  const handleStepChange = (newStepIndex) => {
    if (newStepIndex === currentStepIndex || !paramsId || newStepIndex < 0 || newStepIndex >= validSteps.length) return;

    const newStepSlug = validSteps[newStepIndex];
    const newUrl = `/services/${slug}/${newStepSlug}/${paramsId}`;
    navigate(newUrl);
    setCurrentStep(newStepIndex);
    setCurrentStepIndex(newStepIndex);
    window.location.href = newUrl;
  };

  return (
    <div className="container">
      <Steps
        current={currentStepIndex}
        onChange={handleStepChange}
        labelPlacement="vertical"
        style={{ marginBottom: "20px" }}
        // items={validSteps}
      >
        {validSteps.map((step, index) => (
          <Step
            key={index}
            title={capitalizeFirstLetter(step)}
            icon={iconComponents[index] || null}
          />
        ))}
      </Steps>
      
    </div>
  );
};

export default ProgressBar;
