// Utility function to calculate yearly interest amount
export const calculateYearlyInterest = (fixedDepositAmount, interestRate) => {
    const principal = parseFloat(fixedDepositAmount) || 0;
  
    // Safeguard to ensure interestRate is not undefined
    const cleanedRate = parseFloat((interestRate || "0").replace(/[^\d.]/g, ""));
  
    if (isNaN(principal) || isNaN(cleanedRate) || principal <= 0 || cleanedRate <= 0) {
      return 0; // Return 0 for invalid or missing values
    }
  
    return (principal * cleanedRate) / 100;
  };
  