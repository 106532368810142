import React, { createContext, useContext, useEffect, useState } from "react";

// Create the context
const AnonymousContext = createContext();

// Create a provider component
export const AnonymousProvider = ({ children }) => {
  const [anonymous, setAnonymous] = useState(false);
  const [isAnonymousEditMode, setIsAnonymousEditMode] = useState(false);
  const [slug, setSlug] = useState(""); // State for slug
  const [steps, setSteps] = useState("");
  const [status, setStatus] = useState("");
  const [submissionId, setSubmissionId] = useState(""); // State for submission ID
  const [loading, setLoading] = useState(false);
  const [isAccountValidation, setIsAccountValidation] = useState(false);
  const [brandingScript, setBrandingScript] = useState("");
  const [count, setCount] = useState(0);
  const [cardData, setCardData] = useState(""); // State for card data
  const [currentStep, setCurrentStep] = useState(() => {
    // Check if a currentStep exists in sessionStorage; if not, default to 0 (initial step)
    return parseInt(sessionStorage.getItem("currentStep"), 10) || 0;
  });
  const branding_script = sessionStorage.getItem("branding_script");

  useEffect(() => {
    if (branding_script) {
      setBrandingScript(branding_script);
    }
  }, [branding_script]);
  return (
    <AnonymousContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        anonymous,
        count,
        setCount,
        setAnonymous,
        isAnonymousEditMode,
        setIsAnonymousEditMode,
        slug,
        setSlug,
        submissionId,
        setSubmissionId,
        steps,
        status,
        brandingScript,
        setBrandingScript,
        isAccountValidation,
        setIsAccountValidation,
        setStatus,
        setSteps,
        cardData,
        setCardData,
        loading, // Provide loading state
        setLoading, // Provide setter for loading state
      }}
    >
      {children}
    </AnonymousContext.Provider>
  );
};

// Custom hook to use the Anonymous context
export const useAnonymous = () => useContext(AnonymousContext);
