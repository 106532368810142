import React, { useState } from "react";
import { Input } from "antd";

const CustomTextWidget = (props) => {
  const {
    id,
    value,
    options,
    onChange,
    label,
    schema,
    onBlur,
    formContext,
    disabled,
    readonly,
  } = props;
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  const handleBlur = (e) => {
    const inputValue = e.target.value;

    if (schema?.pattern) {
      const regex = new RegExp(schema.pattern);
      if (!regex.test(inputValue)) {
        setError(schema.errorMessage?.pattern || "Invalid input");
      } else {
        setError("");
      }
    }

    onBlur(id, inputValue); // Notify RJSF on blur
  };
  //console.log("widget", options, label);
  return (
    <div>
      <Input
        id={id}
        value={value || ""}
        onChange={handleChange}
        onBlur={(e) => {
          if (options?.onBlur) {
            options?.onBlur(e);
          }
          handleBlur(e);
        }}
        placeholder={`Enter ${label}`}
        disabled={disabled || readonly}
        {...props.options}
      />
      {!formContext?.liveValidate && error && (
        <div style={{ color: "red", marginTop: "4px" }}>{error}</div>
      )}
    </div>
  );
};

export default CustomTextWidget;

// import React from "react";
// import { Input } from "antd";
// import { useAnonymous } from "../context/anonymousContext";
// // Import if needed to access formContext

// const CustomTextWidget = (props) => {
//   const {
//     value,
//     onChange: onFormChanged,
//     disabled,
//     readonly,
//     label,
//     label,
//     type = "text",
//     id,
//     onBlur,
//     schema, // Pass schema to get validation pattern
//     options,
//     formContext,
//   } = props;
//   //console.log("first", formContext);

//   const { status } = useAnonymous();
//   const [error, setError] = React.useState("");
//   const { initialValue } = options;
//   const handleChange = (e) => {
//     const inputValue = e.target.value;
//     onFormChanged(inputValue);
//   };

//   const handleBlur = (e) => {
//     const inputValue = e.target.value;

//     // Validate the value against the pattern
//     if (schema?.pattern) {
//       const regex = new RegExp(schema.pattern);
//       if (!regex.test(inputValue)) {
//         setError(schema.errorMessage?.pattern || "Invalid input"); // Set error
//       } else {
//         setError(""); // Clear error if validation passes
//       }
//     }

//     if (onBlur) {
//       onBlur(id, inputValue); // Call parent onBlur
//     }
//   };

//   return (
//     <div>
//       <Input
//         type={type}
//         value={initialValue || value || ""}
//         onChange={handleChange}
//         onBlur={handleBlur}
//         placeholder={placeholder || `Enter ${label}`}
//         defaultValue={initialValue}
//         disabled={status === "submitted" || readonly || disabled}
//       />
//       {!formContext?.liveValidate && error && (
//         <div style={{ color: "red", marginTop: "4px" }}>{error}</div>
//       )}{" "}
//       {/* Display error */}
//     </div>
//   );
// };

// export default CustomTextWidget;
