import { Navigate, Outlet } from "react-router-dom";
import BrandingWrapper from "../shared/BrandingWrapper";

const ProtectedRoutes = (token) => {
  if (!token) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
