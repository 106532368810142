import React from "react";
import { Row, Col, Typography, Divider, Tooltip, Flex } from "antd";
import { normalizeTitle } from "../utils";
import { InfoIcon } from "lucide-react";

const { Title } = Typography;

const ObjectFieldTemplate = ({
  properties,
  layoutMode = "single",
  schema = {},
  formData = {},
}) => {
  // Check if a field should be shown based on dependencies
  const shouldShowField = (fieldName) => {
    const fieldDependencies = schema.dependencies?.[fieldName];
    if (!fieldDependencies) return true;

    return fieldDependencies.every((depField) => formData[depField]);
  };

  // Filter properties to handle dependencies
  const visibleProperties = properties.filter((prop) =>
    shouldShowField(prop.name)
  );

  // Group fields and sort as before
  const groupedProperties = visibleProperties.reduce((acc, prop) => {
    const group = schema.properties[prop.name]?.group || "";
    acc[group] = acc[group] || [];
    acc[group].push(prop);
    return acc;
  }, {});

  const sortedGroups = Object.entries(groupedProperties).sort(([a], [b]) => {
    const orderA = schema.groups?.[a]?.order || 999;
    const orderB = schema.groups?.[b]?.order || 999;
    return orderA - orderB;
  });

  const getColSpan = (field) => {
    const width = field.content.props.schema?.width; // Get the width from the schema

    // Return spans based on width property
    if (width === "full") {
      return { xs: 24, sm: 24, md: 24, lg: 24 }; // Full-width
    } else if (width === "half") {
      return { xs: 24, sm: 24, md: 12, lg: 12 }; // Half-width
    }

    // Default to a third-width if no specific width is defined
    return { xs: 24, sm: 12, md: 8, lg: 8 };
  };

  const renderFields = (fields) => (
    <Row gutter={[16, 16]}>
      {fields.map((field) => (
        <Col {...getColSpan(field)} key={field.name}>
          {field.content}
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      {sortedGroups.map(([group, fields]) => (
        <div key={group}>
          {schema.groups?.[group]?.title ||
            (group !== "" && (
              <>
                <Title level={3} className="mt-5" style={{ lineHeight: 0 }}>
                  {normalizeTitle(schema.groups?.[group]?.title || group)}
                </Title>
                <Divider />
              </>
            ))}
          {renderFields(fields)}
        </div>
      ))}
    </>
  );
};

export default ObjectFieldTemplate;

// /* eslint-disable react/prop-types */

// import { Row, Col, Typography, Divider } from "antd";

// const { Title } = Typography;

// const ObjectFieldTemplate = (props) => {

//   const { properties, schema } = props;
//   // Group properties by their group
//   const groupedFields = properties.reduce((acc, field) => {
//     const group = schema.properties[field.name]?.group || "";
//     if (!acc[group]) {
//       acc[group] = [];
//     }
//     acc[group].push(field);
//     return acc;
//   }, {});

//   // Sort groups by their order
//   const sortedGroups = Object.entries(groupedFields).sort((a, b) => {
//     const orderA = schema.groups?.[a[0]]?.order || 999;
//     const orderB = schema.groups?.[b[0]]?.order || 999;
//     return orderA - orderB;
//   });

//   const getColSpan = (totalFields, field) => {
//     const width = field.content.props.schema?.width;

//     if (width === "full") {
//       return { xs: 24, sm: 24, md: 24, lg: 24 }; // Full-width
//     } else if (width === "half") {
//       return { xs: 24, sm: 24, md: 12, lg: 12 }; // Half-width
//     }

//     if (totalFields === 2) {
//       return { xs: 24, sm: 12, md: 12, lg: 12 };
//     }
//     return { xs: 24, sm: 12, md: 8, lg: 8 };
//   };

//   const renderArrayField = (field) => <Col span={24}>{field.content}</Col>;

//   const renderNormalField = (field, totalFields) => (
//     <Col {...getColSpan(totalFields, field)} key={field.name}>
//       {/* Use the field's content directly without adding a new label */}

//       {field.content}
//     </Col>
//   );

//   const renderMapField = (field) => (
//     <Col span={24} key={field.name}>
//       <div>{field.content}</div>
//     </Col>
//   );

//   const renderSection = (groupKey, fields) => {
//     const groupTitle = schema.groups?.[groupKey]?.title || groupKey;

//     // Filter normal fields, array fields, and map fields
//     const normalFields = fields.filter(
//       (field) =>
//         !schema.properties[field.name]?.isMapField &&
//         schema.properties[field.name]?.type !== "array"
//     );
//     const arrayFields = fields.filter(
//       (field) => schema.properties[field.name]?.type === "array"
//     );
//     const mapFields = fields.filter(
//       (field) => schema.properties[field.name]?.isMapField
//     );

//     return (
//       <div key={groupKey}>
//         <Title level={3}>
//           {groupKey !== "other" && normalizeTitle(groupTitle)}
//         </Title>
//         {groupTitle !== "" && <Divider />}

//         {/* Render normal fields in a grid */}
//         {normalFields.length > 0 && (
//           <>
//             <Row gutter={[16, 16]}>
//               {normalFields.map((field) =>
//                 renderNormalField(field, normalFields.length)
//               )}
//             </Row>
//           </>
//         )}

//         {/* Render array fields one by one */}
//         {arrayFields.length > 0 && (
//           <Row gutter={[16, 16]}>
//             {arrayFields.map((field) => renderArrayField(field))}
//           </Row>
//         )}

//         {/* Render map fields at the end with full width */}
//         {mapFields.length > 0 && (
//           <Row gutter={[16, 16]}>
//             {mapFields.map((field) => renderMapField(field))}
//           </Row>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div>
//       {sortedGroups.map(([groupKey, fields]) =>
//         renderSection(groupKey, fields)
//       )}
//     </div>
//   );
// };
// export default ObjectFieldTemplate;
