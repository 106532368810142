import React, { useState } from "react";
import { Button, Popconfirm } from "antd";

const ButtonField = (props) => {
  const { title } = props?.schema;
  const { onClick } = props?.uiSchema[`ui:options`];
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const handleClick = async () => {
    setLoading(true); // Activate loading state
    setVisible(true); // Show the Popconfirm when the button is clicked

    // If onClick is passed from uiSchema, call it as well
    if (onClick) {
      await onClick(); // Wait for the onClick logic to complete
    }
    setLoading(false); // Reset loading after handling the click logic
  };

  const handleSubmit = async () => {
    try {
      setLoading(true); // Activate loading state

      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  const handleConfirm = () => {
    // Navigate to the specified URL
    window.location.href = "https://online.manjushreefinance.com.np/";
    setLoading(false); // Reset loading after navigation
  };

  const handleCancel = () => {
    console.log("User cancelled the action.");
    setLoading(false); // Reset loading state if canceled
  };

  return (
    <div>
      <Button
        htmlType={props?.options?.submission ? "submit" : "button"}
        type="primary"
        loading={loading} // Add loading state to the button
        onClick={
          !loading
            ? props?.options?.submission
              ? handleSubmit // Call handleSubmit if submission is true
              : props?.options?.callHandleclick
              ? handleClick // Call handleClick otherwise
              : undefined
            : undefined // Prevent clicks while loading
        }
      >
        {props.label}
      </Button>

      {/* Popconfirm for confirmation */}
      <Popconfirm
        title="Do you want to open an account before proceeding with the Fixed Deposit account opening?"
        visible={visible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        onVisibleChange={(visible) => setVisible(visible)}
      />
    </div>
  );
};

export default ButtonField;
