import React from "react";

import { Card, Button, Col, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAnonymous } from "../context/anonymousContext";
import { API_URL, IMAGE_URL } from "../constants/authConst";

const CardWidget = (props) => {
  const router = useNavigate();
  const { slug } = useParams();
  const { schema } = props;
  const { cardOptions } = schema;
  //console.log(cardOptions, "schema");
  const { setCardData } = useAnonymous();
  return (
    <div>
      <h1 className="text-black h3 mb-3 fw-700">Products</h1>
      <div className="container py-2">
        <div className="row">
          {cardOptions &&
            cardOptions?.length > 0 &&
            cardOptions?.map((item, index) => (
              <div className="col-md-6 col-sm-12 col-lg-4">
                <div className="services services--secondary">
                  <div className="aspect-ratio-box">
                    <img
                      className="aspect-ratio-box__media services__media"
                      src={`${IMAGE_URL}/${item.image}`}
                    />
                  </div>
                  <div className="services__content services__content--spacing">
                    <div className="services__top border-bottom">
                      <div className="services__title">{item.title}</div>
                      <div className="services__desc">{item.description}</div>

                      <p className="mt-3 fw-light">
                        Minmum Balance:{" "}
                        <div className="fw-bold d-inline">
                          {item.minimumBalance}
                        </div>
                      </p>
                    </div>
                    <div className="services__buttons mt-3 d-flex justify-content-end gap-2">
                      <Button
                        className="btn btn-warning"
                        onClick={() => {
                          setCardData(item.title);
                          router(`/services/${slug}/${item.nextStepSlug}`, {
                            state: { selectedCard: item }, // Pass selectedCard through route state
                          });
                        }}
                      >
                        Apply Now
                      </Button>
                      {item.second_button && (
                        <Button className="btn btn-warning-outline">
                          {" "}
                          {item.second_button || "Details"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CardWidget;

//
