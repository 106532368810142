import React, { useEffect, useState } from "react";
import Form from "@rjsf/antd";
import { Button, Table, Collapse, List, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import validator, { customizeValidator } from "@rjsf/validator-ajv8";
import AccordionWidget from "./AccordionWidget";
import CustomDatePicker from "./CustomDatePicker";
export const dynamicFormValidator = customizeValidator();
const CustomTableWidget = (props) => {
  const { schema, formContext, options, name } = props;
  const { setFormData, formData } = options;
  const [data, setData] = useState([]);
  const [chequeNumbers, setChequeNumbers] = useState(
    schema?.accordionList || []
  );
  useEffect(() => {
    if (formContext?.formData[name]) {
      setData(formContext?.formData[name]);
    }

    // Example cheque numbers for the accordion
    setChequeNumbers(schema.accordionList);
  }, [formContext]);

  // Add a new row to the table
  const addRowToTable = (row) => {
    const newRow = Object.keys(schema.items.properties).reduce((acc, key) => {
      acc[key] = schema.items.properties[key]?.default || null;
      return acc;
    }, {});
    newRow.sn = row.id;
    newRow.chequeNumber = row.title;
    const updatedChequeNumbers = chequeNumbers.filter(
      (item) => item.id !== row.id
    );
    setChequeNumbers(updatedChequeNumbers);

    const updatedData = [...data, newRow];
    setData(updatedData);
    updateFormContext(updatedData);
  };

  // Handle changes in editable cells
  const handleFormChange = (key, updatedValue) => {
    const updatedData = data.map((row) =>
      row.sn === key ? { ...row, ...updatedValue } : row
    );
    setData(updatedData);
    setFormData({ [name]: updatedData });
    updateFormContext(updatedData);
  };

  // Delete a row
  const deleteRow = (key, record) => {
    const updatedData = data.filter((row) => row.sn !== key);
    const updatedCheque = [
      ...chequeNumbers,
      { id: record.sn, title: record.chequeNumber },
    ].sort((a, b) => a.sn - b.sn);

    setChequeNumbers(updatedCheque);
    setData(updatedData);
    updateFormContext(updatedData);
  };

  // Update the formContext
  const updateFormContext = (newData) => {
    if (formContext) {
      formContext.formData[name] = newData;
    }
  };

  // Generate columns dynamically from the schema
  const columns = Object.keys(schema.items.properties).map((key) => ({
    title: schema.items.properties[key].title,
    dataIndex: key,
    render: (_, record) => (
      <Form
        schema={{
          type: "object",
          properties: { [key]: schema.items.properties[key] },
        }}
        formData={{ [key]: record[key] }}
        validator={dynamicFormValidator}
        showErrorList={false}
        uiSchema={{
          [key]: {
            "ui:widget":
              schema.items.properties[key].format === "date"
                ? CustomDatePicker // ? "date"
                : undefined,
            "ui:label": false,
            "ui:placeholder": `Enter ${schema.items.properties[key].title}`,
            "ui:disabled":
              schema.items.properties[key].title === "Cheque Number",
          },
        }}
        onChange={(e) => handleFormChange(record.sn, e.formData)}
      >
        <></>
      </Form>
    ),
  }));

  // Add actions column with "Delete" icon
  columns.push({
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <Tooltip title="Delete">
        <Button
          type="text"
          danger
          icon={<DeleteOutlined />}
          onClick={() => deleteRow(record.sn, record)}
        />
      </Tooltip>
    ),
  });

  return (
    <div>
      {schema?.accordion && (
        <AccordionWidget
          title={"Cheque Number"}
          data={chequeNumbers.sort((a, b) => Number(a.id) - Number(b.id))}
          addFunction={addRowToTable}
        />
      )}
      {/* <Button
        type="primary"
        style={{ marginBottom: "16px" }}
        onClick={() => addRowToTable({})}
      >
        Add Blank Row
      </Button> */}
      {data && data?.length > 0 && (
        <Table
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{}}
        />
      )}
    </div>
  );
};

export default CustomTableWidget;
