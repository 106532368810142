import React from "react";
import { Table, Typography } from "antd";

const TableWidget = (props) => {
  const schema = props?.schema?.tenureIntrestList;
  const columnSchema = props?.schema?.columns || []; // Dynamically fetched column schema
  const safeData = Array.isArray(schema) ? schema : [];

  // Generate columns dynamically from the schema
  const columns = columnSchema.map((col) => ({
    title: col.title,
    dataIndex: col.dataIndex,
    key: col.key,
    render:
      col.dataIndex === "serial"
        ? (_, __, index) => index + 1 // Handle serial number rendering
        : (text) => text || "N/A", // Default fallback rendering
  }));

  return (
    <div className="table-widget-wrapper">
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        FD Interest Rate
      </Typography.Title>

      <Table
        dataSource={safeData.map((item, index) => ({
          ...item,
          key: index, // Ensure each row has a unique key
        }))}
        columns={columns}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </div>
  );
};

export default TableWidget;

// import React, { useState, useEffect } from "react";
// import { Table, Input, Form } from "antd";

// const TableWidgets = ({ schema, formContext }) => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (formContext && formContext.users) {
//       setData(formContext.users);
//     } else {
//       console.error("formContext.users is undefined or empty.");
//     }
//   }, [formContext]);

//   // Update formContext whenever data changes
//   useEffect(() => {
//     if (formContext) {
//       formContext.users = data;
//     }
//   }, [data, formContext]);

//   // Editable cell component
//   const EditableCell = ({
//     title,
//     editable,
//     children,
//     dataIndex,
//     record,
//     handleSave,
//     ...restProps
//   }) => {
//     const [editing, setEditing] = useState(false);
//     const [form] = Form.useForm();

//     const toggleEdit = () => {
//       setEditing(!editing);
//       form.setFieldsValue({ [dataIndex]: record[dataIndex] });
//     };

//     const save = async () => {
//       try {
//         const values = await form.validateFields();
//         toggleEdit();
//         handleSave({ ...record, ...values });
//       } catch (errInfo) {
//         console.error("Save failed:", errInfo);
//       }
//     };

//     let childNode = children;

//     if (editable) {
//       childNode = editing ? (
//         <Form form={form}>
//           <Form.Item
//             name={dataIndex}
//             style={{ margin: 0 }}
//             rules={[{ required: true, message: `${title} is required.` }]}
//           >
//             <Input onPressEnter={save} onBlur={save} />
//           </Form.Item>
//         </Form>
//       ) : (
//         <div onClick={toggleEdit}>{children}</div>
//       );
//     }

//     return <td {...restProps}>{childNode}</td>;
//   };

//   const handleSave = (row) => {
//     const newData = [...data];
//     const index = newData.findIndex((item) => row.key === item.key);
//     if (index > -1) {
//       newData.splice(index, 1, { ...newData[index], ...row });
//       setData(newData);
//     }
//   };

//   const columns = schema.columns.map((column) => ({
//     ...column,
//     onCell: (record) => ({
//       record,
//       editable: column.editable,
//       dataIndex: column.key,
//       title: column.title,
//       handleSave,
//     }),
//   }));

//   return (
//     <div>
//       <h2>{schema.title || "Table"}</h2>
//       <Table
//         components={{
//           body: {
//             cell: EditableCell,
//           },
//         }}
//         columns={columns}
//         dataSource={data}
//         rowKey="key"
//         bordered
//         pagination={{ pageSize: 10 }}
//       />
//     </div>
//   );
// };

// export default TableWidgets;
