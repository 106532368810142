import { Button, Collapse, List } from "antd";
import React from "react";

const { Panel } = Collapse;

const AccordionWidget = ({ title, data, addFunction }) => {
  return (
    <Collapse className="mb-2">
      <Panel header="Cheque Numbers" key="1">
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={
                addFunction && [
                  <Button type="primary" onClick={() => addFunction(item)}>
                    Add
                  </Button>,
                ]
              }
            >
              <div>
                <strong>{title}</strong> {item.title}
              </div>
            </List.Item>
          )}
        />
      </Panel>
    </Collapse>
  );
};

export default AccordionWidget;
