import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message, Select } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import useCreateDraft from "../hooks/useCreateDraft";
import { useParams } from "react-router-dom";
import { useAnonymous } from "../context/anonymousContext";
import { Option } from "antd/es/mentions";
import { BASE_URL } from "../constants/authConst";
const CustomerForm = () => {
  const { slug, stepslug } = useParams();
  const navigate = useNavigate();
  const [fixedDepositType, setFixedDepositType] = useState(null);
  const {
    createDraft,
    loading: draftLoading,
    error: draftError,
  } = useCreateDraft();
  const token = sessionStorage.getItem("token");
  // Handle form submission
  const {
    setSubmissionId,
    submissionId,
    isAccountValidation,
    loading,
    setLoading,
  } = useAnonymous();
  // //console.log(isAccountValidation,"hay")
  const onFinish = async (values) => {
    setLoading(true);
    let currentSubmissionId = submissionId;

    // If there is no existing submissionId and no id from params, create a new draft
    if (!currentSubmissionId) {
      currentSubmissionId = await createDraft();
      setSubmissionId(currentSubmissionId);
      if (!currentSubmissionId) {
        message.error(draftError || "Failed to create draft request.");
        return;
      }

      setSubmissionId(currentSubmissionId);
    }

    const requestBody = {
      request_id: currentSubmissionId,
      accountNumber: values.accountNumber,
      mobileNumber: values.mobileNumber,
      emailAddress: values.emailAddress,
      formSlug: slug,
      ...(slug === "fix-deposit" && {
        fixedDepositType: values.fixedDepositType,
      }),
    };

    try {
      const response = await fetch(
        `${BASE_URL}/validate-customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const result = await response.json();

      if (response.ok && result.success) {
        message.success("Form submitted successfully!");

        // Conditional navigation based on whether id is present
        // if (id) {
        setLoading(false);
        navigate(
          `/services/${slug}/${stepslug}/otp-form/${currentSubmissionId}`,
          {
            state: {
              customerData: requestBody,
              requestId: currentSubmissionId,
              isNewRequest: true,
            },
          }
        );
        // } else {
        //   navigate(`/services/${slug}/${stepslug}/otp-form`, { state: { customerData: requestBody, requestId: currentSubmissionId } });
        // }
      } else {
        message.error(result.error || "Failed to submit form.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred while submitting the form.");
      setLoading(false);
    }
  };

  // Handle form submission failure
  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Customer Form</h2>
        <Form
          name="customer_form"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: 20 }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your account number!",
                  },
                  {
                    pattern: /^\d+$/,
                    message: "Account number must be numeric!",
                  },
                ]}
              >
                <Input placeholder="Account Number" maxLength={16} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mobile Number"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve(); // Skip further validation if no value is provided
                      }
                      if (!/^\d*$/.test(value)) {
                        return Promise.reject(
                          new Error("Mobile number must be numeric!")
                        );
                      }
                      if (value.length !== 10) {
                        return Promise.reject(
                          new Error("Mobile number must be 10 digits!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Mobile Number" maxLength={10} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Email Address"
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input your email address!",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
            </Col>

            {slug === "fix-deposit" && (
              <>
                <Col span={12}>
                  <Form.Item
                    label="Fixed Deposit Type"
                    name="fixedDepositType"
                    rules={[
                      {
                        required: true,
                        message: "Please select a deposit type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Fixed Deposit Type"
                      onChange={(value) => setFixedDepositType(value)}
                    >
                      <Option value="Normal">Normal FD</Option>
                      <Option value="Recurring">Recurring FD</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={12}>
              <Form.Item>
                <ReCAPTCHA
                  style={{ marginTop: 10 }}
                  sitekey="6LeLeHAgAAAAAItPs0MK5Z_Cv-jUl0ClLLavVhbB"
                //   onChange={(value) => //console.log("Captcha value:", value)}
                // 
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ textAlign: "right", marginTop: 60 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",

    // backgroundColor: '#f0f2f5', // Light background for contrast
  },
  formContainer: {
    width: "35%",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0.5, 0, 0.5)",
    borderRadius: "8px",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default CustomerForm;
