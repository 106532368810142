import { useEffect, useState } from "react";
import { Button, Popconfirm, Upload, message } from "antd";
import { useFileUpload } from "../context/FileUploadContext";
import { useAnonymous } from "../context/anonymousContext";
import { useParams } from "react-router-dom";
import { useDraft } from "../context/DraftContext";
import {
  DeleteOutlined,
  InboxOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { BASE_URL } from "../constants/authConst";
const CustomFileUpload = ({ field, label, required, value, ...props }) => {
  const fileFeild = props?.label
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState(props?.options?.initialValue || value);
  //console.log(props.options)
  const [error, setError] = useState(null);
  const { id } = useParams();
  const { submissionId, status } = useAnonymous();
  const { slug, stepslug } = useParams();
  const { updateFileName } = useFileUpload();
  const { draftId, createDraft, isCreatingDraft } = useDraft();
  //console.log(fileName, "name")
  const handleClear = () => {
    setFileList([]);
    setFileName("");
    setError(null);
    props.onChange(""); // Clear the field value
    updateFileName(field, "");
  };

  useEffect(() => {
    if (props?.options?.initialValue) {
      setFileName(props?.options?.initialValue)
    }

  }, [props?.options?.initialValue])
  const beforeUpload = async (file) => {
    const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];
    const isImageFormat = allowedFormats.includes(file.type);

    if (!isImageFormat) {
      const errorMessage = 'You can only upload JPEG, PNG, or GIF files!';
      message.error(errorMessage);
      setError(errorMessage);
      return false; // Prevent file upload
    }

    let currentDraftId = id || draftId || submissionId;

    // Create draft only if draftId is null and not currently creating a draft
    if (!currentDraftId && !isCreatingDraft) {
      currentDraftId = await createDraft(slug, stepslug, sessionStorage.getItem('token'));
      if (!currentDraftId) {
        message.error("Unable to create draft. Please try again.");
        return false; // Prevent upload if draft creation fails
      }
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('request_id', currentDraftId); // Use either the param id or the state draftId
    formData.append('field_name', props.options.name);

    try {
      const response = await fetch(`${BASE_URL}/forms/upload-file`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (response.ok && result.success && result.filename) {
        // Only proceed if a filename is returned
        const uploadedFileName = result.filename;
        setFileName(uploadedFileName);
        setFileList([file]); // Set fileList only after a successful upload
        props.onChange(uploadedFileName); // Set field value to the filename
        updateFileName(field, uploadedFileName);
        message.success(result.message || 'File uploaded successfully!');
      } else {
        const errorMsg = result.message || 'File upload failed.';
        message.error(errorMsg);
        setError(errorMsg);
        props.onChange(""); // Set field value to empty string on failure
        updateFileName(field, "");
        setFileList([]); // Clear fileList on failure
      }
    } catch (error) {
      message.error('Error uploading file.');
      setError('Error uploading file.');
      props.onChange(""); // Clear the field in case of error
      updateFileName(field, "");
      setFileList([]); // Clear fileList on error
    }

    return false; // Prevent default upload behavior
  };

  const customProps = {
    ...props,
    fileList: fileList,
    beforeUpload: beforeUpload,
    onRemove: handleClear,
    disabled: status === 'submitted',
  };

  return (
    <div>
      <Upload.Dragger {...customProps}>
        {/* <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p> */}
        <p className="ant-upload-text">
          {`Click or drag your ${label} to this area
          to upload.`}
          {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
        <p className="ant-upload-hint">
          Extension ( png, jpeg, jpg, pdf :
          Max Size: 5MB )
        </p>
        {/* <Button disabled={status === 'submitted'}>Click to Upload</Button> */}
      </Upload.Dragger>
      {error && (
        <div style={{ color: 'red', marginTop: '10px' }}>Less than 5 MB</div>
      )}
      {fileName && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: "10px",
          gap: '10px'
        }}>
          <span>{fileName}</span>
          <Popconfirm
            title="Are you sure you want to remove this file?"
            onConfirm={handleClear}
            okText="Yes"
            cancelText="No"
            disabled={status === 'submitted'}
          >
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              disabled={status === 'submitted'}
            >
              Remove
            </Button>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default CustomFileUpload;

{/* {fileName && (
        <div style={{ marginTop: "10px" }}>
          <span>Uploaded File: {fileName}</span>
          <br />
          <Button onClick={handleClear} disabled={status === 'submitted'} style={{ marginTop: "10px" }}>
            Clear File
          </Button>
        </div>
      )} */}