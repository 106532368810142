import React, { useState } from "react";
import usePopupModal from "../hooks/popupModal";
import { Checkbox, Form } from "antd";
import ModalWidget from "./ModalWidget";

const PopupCheckboxWidget = (props) => {
  const { uiSchema } = props;
  const { setFormData, formData } = uiSchema;
  const { isOpen, openModal, closeModal } = usePopupModal();
  const [isAccepted, setIsAccepted] = useState();
  return (
    <>
      <div
        className={`row w-max z-2`}
        style={{ width: "fit-content" }}
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
      >
        <Form.Item
          name="accept"
          valuePropName="checked"
          validateTrigger={["onBlur", "onChange"]}
          validateFirst={false}
          rules={[
            {
              required: true,
              message: "Required",
              type: "boolean",
              validator: (_, value) =>
                formData?.accept
                  ? Promise.resolve()
                  : Promise.reject("You must agree to the terms"),
            },
          ]}
        >
          <div className="d-flex">
            <Checkbox
              className="m-0 p-0 me-2"
              checked={isAccepted || false}
              onClick={async (e) => {
                await e.preventDefault();
              }}
              onChange={(e) => e.preventDefault()}
            />
            <div role="button" className="d-flex">
              I accept the terms and conditions
            </div>
          </div>
        </Form.Item>
      </div>

      <ModalWidget
        modalTitle={"Terms and Condition"}
        isOpen={isOpen}
        setIsAccepted={setIsAccepted}
        setFormData={setFormData}
        formData={props?.formContext?.formData}
        closeTermsModal={closeModal}
      />
    </>
  );
};

export default PopupCheckboxWidget;
